import React from "react"

import { Text, Image } from "../../components/Core"
import { Button, ButtonGroup, ButtonGroupMap } from "../../components/Button"

function ImageText(props) {
  // if no complimentary image
  if (!props.textImage) {
    return (
      <section
        className={
          `home-section text-picture home-section-1 top-down` +
          `${props.colorBack ? "color-back" : ""}`
        }>
        <div className="columns">
          <div className="column is-4"></div>
          <div data-aos="fade-up" data-aos-duration="800" id="first-column">
            {props.post.homeSectionOne.heading && (
              <Text
                className="has-text-centered"
                text={props.post.homeSectionOne.heading}
              />
            )}
            <Text text={props.post.homeSectionOne.text} />
            {props.post.homeSectionOne.buttons && (
              <ButtonGroupMap
                alignLeft
                noReverse={props.noReverse}
                buttons={props.post.homeSectionOne.buttons}
              />
            )}
          </div>
          <div className="column is-4"></div>
        </div>
      </section>
    )
  } else if (props.topDown) {
    return (
      <section className="home-section text-picture home-section-1 top-down">
        <div className="columns">
          <div className="column is-2"></div>
          <div data-aos="fade-up" data-aos-duration="800" id="first-column">
            <Image
              publicId={props.post.homeSectionOne.imageId}
              className="full-width"
            />
          </div>
          <div className="column is-2"></div>
        </div>
        <div className="columns">
          <div className="column is-3"></div>
          <div
            className="column"
            data-aos="fade-up"
            data-aos-duration="800"
            id="first-column">
            <Text
              className="has-text-centered"
              text={props.post.homeSectionOne.heading}
            />
            <Text text={props.post.homeSectionOne.text} />

            <ButtonGroup alignLeft noReverse={props.noReverse}>
              <Button
                buttonText={props.post.homeSectionOne.buttonOne.buttonText}
                minimal
                href={props.post.homeSectionOne.buttonOne.href}
              />
              <Button
                buttonText={props.post.homeSectionOne.buttonTwo.buttonText}
                href={props.post.homeSectionOne.buttonTwo.href}
              />
            </ButtonGroup>
          </div>
          <div className="column is-3"></div>
        </div>
      </section>
    )
  }
  if (props.textImage || props.imageText) {
    return (
      <LeftRight
        leftColumn={<TextColumn {...props} />}
        rightColumn={<PictureColumn {...props} />}
      />
    )
  }
}

function LeftRight(props) {
  return (
    <section
      className={
        "home-section text-picture home-section-1" +
        `${props.colorBack ? "color-back" : ""}`
      }>
      <div className="columns is-vcentered reverse-columns">
        <div className="column is-2"></div>
        {props.leftColumn}
        <div className="column is-2 is-hidden-touch-fix"></div>
        {props.rightColumn}
        <div className="column is-2"></div>
      </div>
    </section>
  )
}

function TextColumn(props) {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="800"
      id="first-column"
      className="column text-column mobile-pad">
      <div className="columns">
        <div className="column">
          <Text text={props.post.homeSectionOne.heading} />
          <Text text={props.post.homeSectionOne.text} />
          {props.post.homeSectionOne.buttons && (
            <ButtonGroupMap
              alignLeft
              noReverse={props.noReverse}
              buttons={props.post.homeSectionOne.buttons}
            />
          )}
        </div>
      </div>
    </div>
  )
}

function PictureColumn(props) {
  return (
    <div className="column is-10 picture-column is-hidden-touch-fix">
      <div className="columns">
        <div
          className="column"
          data-aos-duration="1200"
          data-aos="fade-left"
          data-aos-anchor-placement="center-bottom">
          <Image publicId={props.post.homeSectionOne.imageId} />
        </div>
      </div>
    </div>
  )
}

export default ImageText
